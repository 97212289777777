.app__billing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5rem 5rem 0 5rem;
    padding: 5rem;
}

.app__billing-image img {
    width: 600px;
    height: 550px;
}

.app__billing-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
}

.app__billing-content h1 {
    color: #fff;
    font-size: 48px;
    font-weight: 600;
    line-height: 76.8px;
    letter-spacing: 0.48px; 
    width: 70%;
}

.app__billing-content p {
    color: var(--color-text);
    font-size: 18px;
    font-weight: 400;
    line-height: 30.6px;
    letter-spacing: 0.18px; 
    width: 70%;
    padding-bottom: 3rem;
}

.app__billing-content-stores {
    margin-top: 2rem;
}

.app__billing-content-stores img {
    padding-right: 2rem;
}

@media screen and (min-width: 2000px) {
    
}

@media screen and (max-width: 1500px) {
    .app__billing {
        padding: 0;
    }

    .app__billing-content {
        padding-left: 0;
    }

    .app__billing-content h1,
    .app__billing-content p {
        width: 100%;
    }

    .app__billing-content-stores img {
        padding-right:  0;
    }
}

@media screen and (max-width: 1250px) {

    .app__billing-image img {
        width: 500px;
        height: 450px;
    }

    .app__billing-content {
        padding-left: 1rem;
    }

}

@media screen and (max-width: 1000px) {
    .app__billing {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 5rem 0 0 0;
    }

    .app__billing-content {
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .app__billing-content h1,
    .app__billing-content p {
        width: 80%;
        text-align: center;
    }

    .app__billing-content-stores {
        display: flex;
        justify-content: space-between;
        align-items:flex-start;
        width: 100%;
    }

    .app__billing-content-stores img{
        margin: 0 1rem;
    }


    .app__billing-image {
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .app__billing {
        margin-top: 2rem;
    }
    
    .app__billing-image {
        width: 90%;
        margin-top: 3rem;
    }

    .app__billing-image img {
        width: 100%;
        height: auto;
    }
    
}

@media screen and (max-width: 350px) {
    .app__billing-content-stores {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .app__billing-content-stores img {
        padding-bottom: 2rem;
    }
    
}