.app__clients {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7rem 5rem;
    margin:0 auto;
    width: 70%;
}

.app__clients img {
    width: 192.253px;
    height: 37.858px; 
    padding: 1rem 0;
}

@media screen and (max-width: 1250px) {
    .app__clients {
        flex-wrap: wrap;
        justify-content: center;
    }

    .app__clients img {
        padding: 1rem 1rem;
    }
}

@media screen and (max-width: 500px) {
    .app__clients {
        flex-direction: column;
        padding: 5rem 0;
    }

    .app__clients img {
        padding: 1rem 0;
    }
    
}