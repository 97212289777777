.app__hero {
    display: flex;
    justify-content: space-between;
    margin: 5rem 0 0 6rem;
    align-items: center;

}

.app__hero-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.app__hero-content button {
    height: 30px;
    margin-bottom: 2rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--color-text);
    background-color: rgb(225, 126, 12)
}

.app__hero-content button:hover {
    background-color: var(--color-highlight);
}

.app__hero-content button:active {
    opacity: 0.35;
}

.app__hero-content h1 {
    margin: 0;
    padding: 0;
    font-size: 96px;
    color: #fff;
}

.app__hero-content p {
    color: var(--color-text);
    width: 40%;
    font-size: 16px;
    margin-top: 2rem;
}

.app__hero-image {
    width: 800px;
}

.app__hero-image img {
    width: 100%;
}

@media screen and (min-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .app__hero {
        margin-left: 3rem;
    }

    
    .app__hero-content h1 {
        font-size: 64px;
    }

    .app__hero-content p {
        width: 60%;
    }
}

@media screen and (max-width: 800px) {
    .app__hero {
        flex-direction: column;
        margin-left: 0;
    }

    .app__hero-content {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .app__hero-content p {
        width: 80%;
    }

    .app__hero-image {
        width: 100%;
        height: auto;
        margin-top: 4rem;
    }
    
    .app__hero-image img {
        width: 100%;
    }

}

@media screen and (max-width: 400px) {

    .app__hero {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .app__hero-content h1 {
        font-size: 46px;
    }

    .app__hero-content p {
        width: 100%;
    }
}
