.app__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding-top: 1rem;
}

.app__navbar-logo {
    margin: 0 0 0 6rem;
    height: 100%;
}

.app__navbar-logo img {
    width: 232px;
    height: 100%;
}

.app__navbar-links {
    display: flex;
    min-width: 600px;
}

.app__navbar-links_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    width: 100%;
    padding-right: 6rem;
    color: var(--color-text);
}

.app__navbar-links_content a {
    text-decoration: none;
    color:var(--color-text)
}

.app__navbar-links_content a:hover {
    color: var(--color-highlight);
}

.app__navbar-links_content a:active {
    opacity: 0.35;
}

.app__navbar-links_smallscreen {
    display: none;
    color: var(--color-text);
    margin-right: 1rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.app__navbar-links_smallscreen:hover {
    color: var(--color-highlight);
}

.app__navbar-links_smallscreen:active {
    opacity: 0.35;
}

.app__navbar-links_smallscreen_content {
    position: absolute;
    top: 90px;
    right: 1%;
    height: 180px;
    width: 140px;
    background: linear-gradient(
        144.39deg,
        #ffffff -278.56%,
        #6d6d6d -78.47%,
        #11101d 91.61%
      );
    border: 1px solid var(--color-text);
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.app__navbar-links_smallscreen_content a {
    text-decoration: none;
    color: var(--color-text);
}

.app__navbar-links_smallscreen_content a:hover {
    color: var(--color-highlight);
}

.app__navbar-links_smallscreen_content a:active {
    opacity: 0.35;
}

@media screen and (min-width: 2000px) {
    
}

@media screen and (max-width: 1150px) {
    .app__navbar-logo {
        margin: 0 0 0 3rem;
        height: 100%;
    }

    .app__navbar-links {
        display: none;
    }

    .app__navbar-links_smallscreen {
        display: flex;
        margin-right: 3rem;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar-logo img {
        width: 200px;
    }
}

@media screen and (max-width: 350px) {
    .app__navbar-logo img {
        width: 132px;
    }

    .app__navbar-links_smallscreen {
        height: 25px;
        width: 25px;
    }
}